import Home from "./pages/home/Home";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Nav from "./compenents/Nav";
import Footer from "./compenents/Footer";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
]);

function App() {
  return (
      <>
        <Nav/>
          <RouterProvider router={router}/>
        <Footer/>
      </>
  );
}

export default App;
