import { APP_PHONE_NUMBER } from "../constants/constants";
import AddIcCallIcon from '@mui/icons-material/AddIcCall';

function Nav() {
    return (
        <nav className="navbar fixed-top navbar-expand-lg bg-white shadow-lg">
            <div className="container">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <a className="navbar-brand" href="/">
                    <img src="/logo-nav.png" alt="logo" width={130} />
                </a>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav mx-auto">
                        <li className="nav-item">
                            <a className="nav-link active" href="/">Home</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href="/#story">Story</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href="/#catalog">Our Catalog</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href="/#appointment">Appointment</a>
                        </li>

                        {/* <li className="nav-item">
                            <a className="nav-link" href="/#contact">Contact</a>
                        </li> */}
                    </ul>
                </div>

                <div className="d-none d-lg-block">
                    <a href={`tel:${APP_PHONE_NUMBER}`}>
                        <button type="button" className="custom-btn btn btn-success" data-bs-toggle="modal"
                            data-bs-target="#BookingModal"> <AddIcCallIcon /> Call Us
                        </button>
                    </a>
                </div>

            </div>
        </nav>
    );
}

export default Nav;