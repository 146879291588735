function Hero() {
    return (
        <section className="hero">
            <div className="container">
                <div className="row">

                    <div className="col-lg-5 col-12 m-auto">
                        <div className="heroText">

                            <h1 className="text-white mb-lg-5 mb-3">Glory Hair Braiding</h1>

                            <div className="c-reviews my-3 d-flex flex-wrap align-items-center">
                                <p className="text-white w-100">You will leave lookin Sharp, relaxed and ready to take on the world, with that swagger in your stride</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 col-12">
                        <div className="col-lg-6 col-md-6 col-12 image-presentation">
                            <img src="/images/styles/box-braid/10.jpg" className="img-fluid menu-image" alt="" />
                        </div>
                    </div>

                </div>
            </div>
            <div className="overlay2"></div>
            <div className="overlay"></div>
        </section>
    );
}

export default Hero;