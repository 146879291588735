import { APP_ADDRESS } from "../../constants/constants";

function Contact() {
    return (
        <section className="">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h4 className="mt-5 mb-4"> {APP_ADDRESS} </h4>

                        <div className="google-map pt-3">
                            <iframe title="address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3257.282722036544!2d-80.7685764236536!3d35.27409637272153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88541e5f5e45464f%3A0xf2e06271931c7470!2s6320%20N%20Tryon%20St%20g%2C%20Charlotte%2C%20NC%2028213%2C%20%C3%89tats-Unis!5e0!3m2!1sen!2stg!4v1694809572326!5m2!1sen!2stg" width="600" height="450" style={{ border: "0", width: "100%" }} loading="lazy"></iframe>
                        </div>
                    </div>

                </div>
                {/* <div id="contact" className="row contact section-padding">

                    <div className="col-12">
                        <h2 className="mb-4">Leave a message</h2>
                    </div>

                    <div className="col-lg-6 col-12">
                        <form className="custom-form contact-form row" action="#" method="post">
                            <div className="col-lg-6 col-6">
                                <label htmlFor="contact-name" className="form-label">Full Name</label>

                                <input type="text" name="contact-name" id="contact-name" className="form-control"
                                       placeholder="Your Name" required />
                            </div>

                            <div className="col-lg-6 col-6">
                                <label htmlFor="contact-phone" className="form-label">Phone Number</label>

                                <input type="telephone" name="contact-phone" id="contact-phone"
                                       pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" className="form-control"
                                       placeholder="123-456-7890" />
                            </div>

                            <div className="col-12">
                                <label htmlFor="contact-email" className="form-label">Email</label>

                                <input type="email" name="contact-email" id="contact-email" pattern="[^ @]*@[^ @]*"
                                       className="form-control" placeholder="Your Email" required={true} />

                                    <label htmlFor="contact-message" className="form-label">Message</label>

                                    <textarea className="form-control" rows={5} id="contact-message"
                                              name="contact-message" placeholder="Your Message" ></textarea>
                            </div>

                            <div className="col-lg-5 col-12 ms-auto">
                                <button type="submit" className="form-control">Send</button>
                            </div>
                        </form>
                    </div>

                    <div className="col-lg-4 col-12 mx-auto mt-lg-5 mt-4">

                        <h5>Weekdays</h5>

                        <div className="d-flex mb-lg-3">
                            <p>Monday to Friday</p>

                            <p className="ms-5">08:00 AM - 09:00 PM</p>
                        </div>

                        <h5>Weekends</h5>

                        <div className="d-flex mb-lg-3">
                            <p>Saturday</p>

                            <p className="ms-5">08:00 AM - 09:00 PM</p>
                        </div>

                        <div className="d-flex">
                            <p>Sunday</p>

                            <p className="ms-5">09:00 AM - 07:00 PM</p>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
    );
}

export default Contact;