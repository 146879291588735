import React from 'react';
import About from '../../sections/home/About';
import Catalog from '../../sections/home/Catalog';
import Contact from '../../sections/home/Contact';
import Hero from '../../sections/home/Hero';
import Appointment from '../../sections/home/Appointment';

function Home() {
    return (
        <>
            <Hero/>
            <Catalog/>
            <section className="BgImage"></section>
            <About/>
            <Appointment/>
            <Contact/>
        </>
    );
}

export default Home;