import { APP_PHONE_NUMBER } from "../../constants/constants";
import AddIcCallIcon from '@mui/icons-material/AddIcCall';

function About() {
    return (
        <>
            <section id="story" className="about section-padding bg-white">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-12">
                            <h4 className="mb-3">Come experience the best hair braiding touch everybody is talking about. You will be in love with your hair again!</h4>

                            <a href={`tel:${APP_PHONE_NUMBER}`}>
                                <button type="button" className="custom-btn btn btn-success" data-bs-toggle="modal"
                                    data-bs-target="#BookingModal"> <AddIcCallIcon /> Call Us
                                </button>
                            </a>
                        </div>

                        <div className="col-lg-6 col-12">

                            <p>Glory Hair Braiding is one of the Best Premium Hair Braiding Salon in Charlotte
                                NC area who specializing in braids of all types for men, women and children.
                                Glory Hair Braiding brings you the best in African hair braiding and offers
                                the best services in town. Full service comfort, friendly staff,
                                a relaxing atmosphere, and the best prices!</p>

                            <p>Our Professional Braiders have experience with Senegalese twists, box braids,
                                micro braids, corn rows, flat twists, hair weaving, and much more.
                                Our highly skilled team of Braiders will help you select the hair
                                and style from the wide range of hair.</p>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default About;