import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as appointments from "../../services/firestore/appointments";
import { AppointmentDto } from "../../dto/appointment_dto";

// Thunks
export const addAppointment = createAsyncThunk<string, { appointment: AppointmentDto }>(
    "appointments/addAppointment",
    async (args) => {
        const { appointment } = args;
        return await appointments.addAppointment(appointment);
    }
);

interface AppointmentsState {
    appointments: Array<AppointmentDto>
};

const initialState: AppointmentsState = {
    appointments: [],
};

const appointmentsSlice = createSlice({
    name: "appointments",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
    }
});

// export const { } = appointmentsSlice.actions;
export default appointmentsSlice.reducer;