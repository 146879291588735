import { useState } from 'react';
import { catalogList } from '../../constants/constants';
import { CatalogModel } from '../../models/CatalogModel';
import { Dialog, DialogContent, DialogTitle, IconButton, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const StyleImg = styled("img")(() => ({
    width: "50px",
    height: "50px",
    objectFit: "cover",
    margin: "5px",
    border: "1px solid #1A1B20"
}));
const StyleImgContainer = styled("div")(({ theme }) => ({
    display: "grid",
    gridGap: "10px",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridAutoRows: "minmax(100px, auto)",
    [theme.breakpoints.down("lg")]: {
        gridTemplateColumns: "repeat(3, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "repeat(2, 1fr)",
    }
}));

function Catalog() {
    const [selectedCatalog, setSelectedCatalog] = useState<CatalogModel>();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const showModal = (catalog: CatalogModel) => {
        setSelectedCatalog(catalog);
        setOpen(true);
    }

    return (
        <>
            <section id="catalog" className="menu section-padding">
                <div className="container">
                    <div className="row">

                        <div className="col-12">
                            <h2 className="text-center mb-lg-5 mb-4">Our Catalog</h2>
                        </div>

                        {
                            catalogList.map((one, i) =>
                                <div key={i} onClick={() => showModal(one)} style={{ cursor: "pointer" }} className="col-lg-4 col-md-6 col-12">
                                    <div className="menu-thumb">
                                        <div className="menu-image-wrap">
                                            <img src={`/images/styles/${one.url}`}
                                                className="img-fluid menu-image" alt="" />

                                            <span className="menu-tag bg-warning">{one.name}</span>
                                        </div>

                                        <div className="menu-info d-flex flex-wrap align-items-center">
                                            <h4 className="mb-0">{one.name}</h4>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>
            </section>
            <Dialog open={open} onClose={handleClose} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
                <DialogTitle>
                    {selectedCatalog?.name}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <StyleImgContainer>
                        {selectedCatalog?.urls.map((img, i) => <StyleImg key={i} sx={{ width: "120px", height: "120px" }} src={`/images/styles/${img}`} />)}
                    </StyleImgContainer>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default Catalog;